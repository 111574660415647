import React, { useState, useEffect, useRef } from 'react';
import styles from './App.module.scss';
import io from 'socket.io-client';

const SERVER_URL = process.env.REACT_APP_SERVER_URL || 'http://localhost:3001';
const socket = io(SERVER_URL);

type Message = string;

const ChatWindow: React.FC = () => {
  const [messages, setMessages] = useState<Message[]>([]);
  const [inputValue, setInputValue] = useState('');
  const bottomOfChat = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null); // 입력 필드를 위한 ref 생성

  const addSystemMessage = (message: string) => {
    setMessages((prevMessages) => [...prevMessages, `System: ${message}`]);
  };

  const addMessage = (message: Message) => {
    setMessages((prevMessages) => {
      const newMessages = [...prevMessages, message];
      if (newMessages.length > 100) {
        newMessages.shift();
      }
      return newMessages;
    });
  };

  useEffect(() => {
    inputRef.current?.focus();

    socket.on('connect', () => {
      addSystemMessage('Connected to the server.');
    });

    socket.on('disconnect', () => {
      addSystemMessage('Disconnected from the server.');
    });

    socket.on('reconnect_attempt', () => {
      addSystemMessage('Attempting to reconnect...');
    });

    socket.on('reconnect', () => {
      addSystemMessage('Reconnected to the server.');
    });

    socket.on('chat message', (msg: Message) => {
      addMessage(msg);
    });

    return () => {
      socket.off('connect');
      socket.off('disconnect');
      socket.off('reconnect_attempt');
      socket.off('reconnect');
      socket.off('chat message');
    };
  }, []);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  const handleSend = (e: React.FormEvent) => {
    e.preventDefault(); // 폼 제출 이벤트의 기본 동작 막기
    if (inputValue.trim() !== '') {
      socket.emit('chat message', inputValue); // 서버로 메시지 보내기
      setInputValue('');
    }
  };

  useEffect(() => {
    bottomOfChat.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages]);

  return (
      <div className={styles.container}>
        <div className={styles.chat_window}>
          {/*{messages.map((message, index) => (*/}
          {/*    <div key={index}>{message}</div>*/}
          {/*))}*/}
          {messages.map((message, index) => (
              <div key={index} dangerouslySetInnerHTML={{ __html: message }} />
          ))}
          <div ref={bottomOfChat} />
        </div>
        <form onSubmit={handleSend}>
          <input
              type="text"
              value={inputValue}
              onChange={handleInputChange}
              className={styles.chat_input}
              ref={inputRef}
              autoComplete="off"
              placeholder="메세지를 입력하세요"
          />
        </form>
      </div>
  );
};

export default ChatWindow;
